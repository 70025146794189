import * as React from 'react';
import pluralize from 'pluralize';
import { flatten } from 'lodash-es';

import { CourseUtils, Course, Prices, PriceBreakdown, RateType, RecurringPrice, RecurringBillingUnit, RecurringUnit, UsagePrice, UsageUnit, SeasonPrice  } from 'app2/api';
import { Body, commaOr, formatCurrency, Icon, renderDelimitedList, TextProps } from 'app2/components';
import { FeeBreakdown, FeeBreakdownType } from 'app2/views/shared';

import { CartCourse, CourseKindBehavior, courseKindBehavior } from '../../';

export type CourseBreakdown = FeeBreakdownType & Pick<PriceBreakdown, 'price'>
export type CourseBreakdowns = {season?:CourseBreakdown, seasons?:CourseBreakdown[], dropIn?:CourseBreakdown, recurring?:CourseBreakdown[], usage?:CourseBreakdown[]}
export type CourseWithPrices = Pick<Course, 'classesCount' | 'kind'> & {prices?:Partial<Prices>, priceBreakdowns?:CourseBreakdowns} & Partial<CartCourse>;

export interface CoursePricesProps extends TextProps {
  course: CourseWithPrices;
  breakdowns?: CourseBreakdowns;
}

export function CoursePrices(props:CoursePricesProps) {
  const {course, breakdowns, ...remaining} = props;
  const behavior = courseKindBehavior[props.course.kind];

  const prices = props.breakdowns ? props.breakdowns : props.course?.prices || props.course?.priceBreakdowns;
  const formattedPrices = formatCoursePrices(behavior, prices, props.breakdowns, props.course?.classesCount);

  return <Body {...remaining}>{renderDelimitedList(formattedPrices, commaOr)}</Body>
}

// prices is specified, then that will override whats in the course
export function formatCoursePrices(behavior:CourseKindBehavior, prices:Partial<Prices>, breakdowns?:CourseBreakdowns, classCount?:number) {
  if (!prices) {
    return [];
  }
  
  // season only pricing is different
  const seasonOnly = CourseUtils.getRateOrPriceType(prices) == RateType.basic;

  return seasonOnly
    ? [seasonOnlyPrice(behavior, prices, classCount, breakdowns?.season)]
    : flatten([
      formatSeasonPrice(behavior, prices, breakdowns),
      formatConfigurableSeasonPrices(behavior, prices, breakdowns),
      formatAllRecurringPrices(prices, breakdowns),
      formatDropInPrice(prices, breakdowns),
      prices.usage?.map((u, i) => (formatUsagePrice(u, undefined, breakdowns?.usage[i])))
    ]).filter(p => !!p)
}

function seasonOnlyPrice(behavior:CourseKindBehavior, prices:Partial<Prices>, classCount:number, breakdown:CourseBreakdown) {
  if (!CourseUtils.isValidRateOrPrice(prices?.season)) {
    return ''
  }

  return withBreakdown(`${formatCurrency(prices?.season?.price)} ` + (behavior?.showSessionCount && classCount ? `for ${classCount} ${pluralize(behavior?.terms.session || '', classCount)}`: ''), breakdown);
}

export function formatSeasonPrice(behavior:CourseKindBehavior, prices:Partial<Prices>, breakdowns?:CourseBreakdowns) {
  return formatPriceAndLabel(prices.season?.price, behavior.terms.season, breakdowns?.season);
}

export function formatConfigurableSeasonPrices(behavior:CourseKindBehavior, prices:Partial<Prices>, breakdowns?:CourseBreakdowns) {
  const seasonsBreakdowns = breakdowns?.seasons;
  const rates = prices.seasons?.map((p, i) => ({price:p, breakdown: seasonsBreakdowns?.[i]}));

  if (!rates.length) {
    return
  }

  const min = rates.reduce((prev, curr) => prev.price.price < curr.price.price ? prev : curr);
  const max = rates.reduce((prev, curr) => prev.price.price > curr.price.price ? prev : curr);

  return min.price.price == max.price.price
    ? formatPriceAndLabel(min.price.price, behavior.terms.season, min?.breakdown)
    : seasonsBreakdowns
      ? <>{withBreakdown(`${formatCurrency(min.price.price)}`,min.breakdown)}-{withBreakdown(`${formatCurrency(max.price.price)}`, max.breakdown)}/{behavior.terms.season}</>
      : `${formatCurrency(min.price.price)}-${formatCurrency(max.price.price)}/${behavior.terms.season}`
}

export function formatConfigurableSeasonPrice(behavior:CourseKindBehavior, p:SeasonPrice, alternatePrice?:number, days?:string[]) {
  return `${p.days} ${pluralize('day', p.days)} ${days ? `(${days.map(w => w.slice(0, 3)).join(', ')}) ` : ''}@ ${formatCurrency(alternatePrice ?? p.price)}/${behavior.terms.season}`
}

export function formatDropInPrice(prices:Partial<Prices>, breakdowns?:CourseBreakdowns) {
  return formatPriceAndLabel(prices.dropIn?.price, 'day', breakdowns?.dropIn);
}

export function formatUsagePrice(u:Partial<UsagePrice>, alternatePrice?:number, breakdown?:CourseBreakdown, description?:boolean) {
  return formatPriceAndLabel(alternatePrice ?? u.price, coursePriceUnitLabels[u.unit] + (description !== false ? ' (flexible attendance, pay for what you use)' : ''), breakdown)
}

export function formatPriceAndLabel(price:number, label?:string, breakdown?:CourseBreakdown) {
  if (!Number.isFinite(price)) {
    return;
  }

  return withBreakdown(`${formatCurrency(price)}${label ? '/' + label : ''}`, breakdown)
}

export function formatAllRecurringPrices(prices:Partial<Prices>, breakdowns?:CourseBreakdowns) {
  return prices.recurring?.map((p) => formatRecurringPrices(prices, p.billingUnit, p.billingFrequency, breakdowns?.recurring));
}

function formatRecurringPrices(prices:Partial<Prices>, billingUnit:RecurringBillingUnit, billingFrequency:RecurringUnit, breakdowns:CourseBreakdown[]) {
  const recurringRates = prices.recurring?.map((p, i) => ({price:p, breakdown: breakdowns?.[i]})).filter(s => s.price.billingUnit == billingUnit && s.price.billingFrequency == billingFrequency);

  if (!recurringRates.length) {
    return
  }

  const min = recurringRates.reduce((prev, curr) => prev.price.price < curr.price.price ? prev : curr);
  const max = recurringRates.reduce((prev, curr) => prev.price.price > curr.price.price ? prev : curr);

  return min.price.price == max.price.price
    ? withBreakdown(`${formatCurrency(min.price.price)} per ${coursePriceUnitLabels[billingUnit]}, billed ${coursePriceUnitAdverbs[billingFrequency]}`, min.breakdown)
    : breakdowns
      ? <>{withBreakdown(`${formatCurrency(min.price.price)}`,min.breakdown)}-{withBreakdown(`${formatCurrency(max.price.price)}`, max.breakdown)} per ${coursePriceUnitLabels[billingUnit]}, billed ${coursePriceUnitAdverbs[billingFrequency]}</>
      : `${formatCurrency(min.price.price)}-${formatCurrency(max.price.price)} per ${coursePriceUnitLabels[billingUnit]}, billed ${coursePriceUnitAdverbs[billingFrequency]}`
}

export function formatRecurringPrice(p:RecurringPrice, alternatePrice?:number, days?:string[]) {
  // happens when called from formatPriceConfig and it couldn't find a valid config
  if (!p) {
    return '';
  }

  const daysText = `${p.days} ${pluralize('day', p.days)}/week`;
  const daysSchedule = days ? ` (${days.map(w => w.slice(0, 3)).join(', ')})` : '';
  
  return `${formatCurrency(alternatePrice ?? p.price)} per ${coursePriceUnitLabels[p.billingUnit]} for ${daysText}${daysSchedule}, billed ${coursePriceUnitAdverbs[p.billingFrequency]}`;
}

function withBreakdown(text:string, breakdown:CourseBreakdown) {
  return breakdown && text.length
    ? <>{text}<Icon name='Info' size='small' ml="2px" tooltipPosition='bottom right' tooltip={<FeeBreakdown {...breakdown} boxProps={{ p:'4px', bg: 'white', width: '300px' }} />} /></>
    : text;
}

export const coursePriceUnitLabels = {
  ['']:'',
  [String(null)]: '',
  [String(undefined)]: '',
  [RecurringUnit.Month]: 'month',
  [RecurringUnit.Week]: 'week',
  [UsageUnit.Day]: 'day',
  [UsageUnit.Hour]: 'hour'
}

export const coursePriceUnitAdverbs = {
  ['']:'',
  [String(null)]: '',
  [String(undefined)]: '',
  [RecurringUnit.Month]: 'monthly',
  [RecurringUnit.Week]: 'weekly',
  [UsageUnit.Day]: 'daily',
  [UsageUnit.Hour]: 'hourly'
}

export const coursePriceBillingAdverbs = {
  ['']:'',
  [String(null)]: '',
  [String(undefined)]: '',
  ['installment']: 'installment',
  [RecurringUnit.Month]: 'monthly',
  [RecurringUnit.Week]: 'weekly',
  [UsageUnit.Day]: 'monthly',
  [UsageUnit.Hour]: 'monthly'
}

export const recurringBillingUnitLabels = {
  ['']:'',
  [String(null)]: '',
  [String(undefined)]: '',
  [RecurringBillingUnit.Month]: 'month',
  [RecurringBillingUnit.Week]: 'week',
  [RecurringBillingUnit.Day]: 'day',
}

