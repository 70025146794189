import * as React from 'react';
import moment from 'moment';

import { CourseUtils, EnrollmentInput, PriceConfigKind } from 'app2/api';
import { PopupManager, SaveableResult, Modal } from 'app2/components';

import { EnrollmentConfigurationModal, EnrollmentCourseOptionsModal, EnrollmentOptionsFormValues } from '../cart';

import { CartCourse } from './Cart';
import { EnrollmentForm } from './EnrollmentConfigurationForm';

export async function configureEnrollment(course:CartCourse) {
  const prompt = CourseUtils.schedulableAndNeedsPrompt(course);

  if (prompt) {
    return promptSchedulableAndAddCourse(course);
  }
  else
  if (course.hasCheckoutOptions && !course.waitlistOpen) {
    return promptOptionsAndAddConfigType(course);
  }
  else {
    return addConfigType(course);
  }
}

async function promptSchedulableAndAddCourse(course:CartCourse):Promise<EnrollmentInput> {
  const result = await PopupManager.addModal(<EnrollmentConfigurationModal course={course} />);

  if (result.action == SaveableResult.cancel) {
    return null;
  }

  const enrollment = result.result;

  if (pastDates(enrollment)) {
    const result = await showPastDatesWarning();

    if (result.action == SaveableResult.cancel) {
      return null;
    }
  }

  if (course.waitlistOpen || !course.hasCheckoutOptions) {
    return addCourse(course, enrollment);
  }

  const options = await showOptionConfigurationModal(course);

  if (!options) {
    return null;
  }

  return addCourse(course, {...enrollment, options: options.choices});
}

async function promptOptionsAndAddConfigType(course:CartCourse):Promise<EnrollmentInput> {
  const options = await showOptionConfigurationModal(course);

  if (!options) {
    return null;
  }

  return addConfigType(course, null, options);
}

async function showOptionConfigurationModal(course:CartCourse) {
  const result = await PopupManager.addModal<EnrollmentOptionsFormValues>(<EnrollmentCourseOptionsModal course={course.id} checkout />);

  return result.action == SaveableResult.cancel ? null : result.result;
}

async function addConfigType(course:CartCourse, enrollment?:Partial<EnrollmentInput>, options?:EnrollmentOptionsFormValues):Promise<EnrollmentInput> {
  if (CourseUtils.usingBasicSeasonRateOrPrice(course.prices)) {
    return addCourse(course, {kind:PriceConfigKind.Season, ...enrollment, options: options?.choices});
  }
  else
  if (CourseUtils.usingConfigurableSeasonRatesOrPrices(course.prices)) {
    return addCourse(course, {kind:PriceConfigKind.ConfigurableSeason, configurableSeason:{weekdays: CourseUtils.getCourseWeekdays(course), ...enrollment}, options: options?.choices});
  }
  else
  if (CourseUtils.usingUsageRateOrPrice(course.prices)) {
    return addCourse(course, {kind:PriceConfigKind.Usage, ...enrollment, options: options?.choices});
  }
  else 
  if (course.prices?.recurring?.length) {
    const recurring = course.prices.recurring[0];
    const billingUnit = recurring.billingUnit;
    const billingFrequency = recurring.billingFrequency;
    const weekdays = CourseUtils.getCourseWeekdays(course);

    return addCourse(course, {kind:PriceConfigKind.Recurring, recurring:{billingUnit, billingFrequency, weekdays, ...enrollment}, options: options?.choices});
  }
  else {
    Modal.error("Can't enroll", `This activity has no pricing options. Please contact ${course.company?.name || 'the activity provider'} for more information.`);
    return;
  }
}

function addCourse(course:CartCourse, enrollment:Partial<EnrollmentInput>):EnrollmentInput {
  return {id: course.id, ...enrollment};
}

function pastDates(enrollment:Partial<EnrollmentForm>) {
  const today = moment().startOf('day');
  switch (enrollment.kind) {
    case PriceConfigKind.Recurring:
      return moment(enrollment.recurring.startDate).startOf('day').isBefore(today);
    case PriceConfigKind.DropIn:
      return enrollment.dropIn.dates.some(d => moment(d).startOf('day').isBefore(today));
    default:
      return false;
  }
}

function showPastDatesWarning() {
  return Modal.warning('Past dates', 'You selected dates in the past. Are you sure you want to continue?', true);
}