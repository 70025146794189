import * as React from 'react';

import { Rates } from 'app2/api';
import { Field, RepeatingSection, RepeatingSectionProps } from 'app2/components';

import { useCourseFields } from '../course';

export type RecurringFields = Pick<ReturnType<typeof useCourseFields>['fields'], 'rates.recurring' | 'rates.recurring.days' | 'rates.recurring.rate' | 'rates.recurring.billingFrequency' | 'rates.recurring.billingUnit' | 'rates.recurring.homeroomFeeRoundingIncrementCents'>;

interface Props extends Partial<RepeatingSectionProps<Rates>> {
  recurringFields:RecurringFields;
}

export function CourseRecurringRates(props:Props) {
  const {recurringFields, ...remaining} = props;

  function render() {
    return <RepeatingSection assignIds={false} name={recurringFields['rates.recurring'].name} labelType='formlabel' numbered={false} alwaysOne add='Add subscription option' fields={[
      <Field {...recurringFields['rates.recurring.days']} />,
      <Field {...recurringFields['rates.recurring.billingUnit']} />,
      <Field {...recurringFields['rates.recurring.rate']} />,
      <Field {...recurringFields['rates.recurring.billingFrequency']} />,
      <Field {...recurringFields['rates.recurring.homeroomFeeRoundingIncrementCents']} />,
      'remove'
    ]} {...remaining} />
  }

  return render();
}

