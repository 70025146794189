import * as Types from '../../../../../../../api/graphql';

import gql from 'graphql-tag';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type EnrollmentPriceBreakdownQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  discount?: Types.InputMaybe<Types.DiscountInput>;
  discountId?: Types.InputMaybe<Types.Scalars['ID']>;
  kind: Types.PriceConfigKind;
  recurring?: Types.InputMaybe<Types.RecurringPriceConfigInput>;
  dropIn?: Types.InputMaybe<Types.DropInPriceConfigInput>;
  configurableSeason?: Types.InputMaybe<Types.SeasonPriceConfigInput>;
  custom?: Types.InputMaybe<Types.CustomPriceConfigInput>;
  changingEnrollment?: Types.InputMaybe<Types.Scalars['ID']>;
  effective?: Types.InputMaybe<Types.ChangePricingPeriod>;
  studentId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type EnrollmentPriceBreakdownQuery = {
  enrollmentPriceBreakdown?:
    | {
        priceConfig:
          | { kind: Types.PriceConfigKind; template: boolean }
          | { kind: Types.PriceConfigKind; template: boolean }
          | { billingUnit: Types.RecurringBillingUnit; billingFrequency: Types.RecurringUnit; weekdays: Array<string>; startDate: string; kind: Types.PriceConfigKind; template: boolean }
          | { weekdays: Array<string>; kind: Types.PriceConfigKind; template: boolean }
          | { kind: Types.PriceConfigKind; template: boolean; usageUnit: Types.UsageUnit };
        standard?:
          | {
              amount?: number | null | undefined;
              discountAmount?: number | null | undefined;
              listPrice?: number | null | undefined;
              homeroomCharges?: number | null | undefined;
              siteCharges?: number | null | undefined;
              siteCreditCardFee?: number | null | undefined;
              siteReceives?: number | null | undefined;
              vendorCharges?: number | null | undefined;
              vendorCreditCardFee?: number | null | undefined;
              vendorReceives?: number | null | undefined;
              discounts: Array<{ code: string; amount: number; isStudent: boolean; oneOff: boolean } | null | undefined>;
            }
          | null
          | undefined;
        first?:
          | {
              refund: number;
              charges: Array<
                | {
                    amount?: number | null | undefined;
                    date?: string | null | undefined;
                    discountAmount?: number | null | undefined;
                    listPrice?: number | null | undefined;
                    period?: string | null | undefined;
                    prorated?: boolean | null | undefined;
                    homeroomCharges?: number | null | undefined;
                    siteCharges?: number | null | undefined;
                    siteCreditCardFee?: number | null | undefined;
                    siteReceives?: number | null | undefined;
                    vendorCharges?: number | null | undefined;
                    vendorCreditCardFee?: number | null | undefined;
                    vendorReceives?: number | null | undefined;
                    discounts: Array<{ code: string; amount: number; isStudent: boolean; oneOff: boolean } | null | undefined>;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
        future?:
          | {
              charges: Array<
                | {
                    amount?: number | null | undefined;
                    date?: string | null | undefined;
                    discountAmount?: number | null | undefined;
                    listPrice?: number | null | undefined;
                    prorated?: boolean | null | undefined;
                    homeroomCharges?: number | null | undefined;
                    siteCharges?: number | null | undefined;
                    siteCreditCardFee?: number | null | undefined;
                    siteReceives?: number | null | undefined;
                    vendorCharges?: number | null | undefined;
                    vendorCreditCardFee?: number | null | undefined;
                    vendorReceives?: number | null | undefined;
                    discounts: Array<{ code: string; amount: number; isStudent: boolean; oneOff: boolean } | null | undefined>;
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SellerFieldsFragment = {
  homeroomCharges?: number | null | undefined;
  siteCharges?: number | null | undefined;
  siteCreditCardFee?: number | null | undefined;
  siteReceives?: number | null | undefined;
  vendorCharges?: number | null | undefined;
  vendorCreditCardFee?: number | null | undefined;
  vendorReceives?: number | null | undefined;
};

export type DiscountsFieldsFragment = { discounts: Array<{ code: string; amount: number; isStudent: boolean; oneOff: boolean } | null | undefined> };

export const SellerFieldsFragmentDoc = gql`
  fragment sellerFields on ChargeBreakdown {
    homeroomCharges
    siteCharges
    siteCreditCardFee
    siteReceives
    vendorCharges
    vendorCreditCardFee
    vendorReceives
  }
`;
export const DiscountsFieldsFragmentDoc = gql`
  fragment discountsFields on ChargeBreakdown {
    discounts {
      code
      amount
      isStudent
      oneOff
    }
  }
`;
export const EnrollmentPriceBreakdownDocument = gql`
  query EnrollmentPriceBreakdown(
    $id: ID!
    $discount: DiscountInput
    $discountId: ID
    $kind: PriceConfigKind!
    $recurring: RecurringPriceConfigInput
    $dropIn: DropInPriceConfigInput
    $configurableSeason: SeasonPriceConfigInput
    $custom: CustomPriceConfigInput
    $changingEnrollment: ID
    $effective: ChangePricingPeriod
    $studentId: ID
  ) {
    enrollmentPriceBreakdown(
      id: $id
      discount: $discount
      discountId: $discountId
      kind: $kind
      recurringPriceConfig: $recurring
      dropInPriceConfig: $dropIn
      configurableSeason: $configurableSeason
      custom: $custom
      changingEnrollment: $changingEnrollment
      effective: $effective
      studentId: $studentId
    ) {
      priceConfig {
        kind
        template
        ... on RecurringPriceConfig {
          billingUnit
          billingFrequency
          weekdays
          startDate
        }
        ... on SeasonPriceConfig {
          weekdays
        }
        ... on UsagePriceConfig {
          usageUnit: unit
        }
      }
      standard {
        amount
        discountAmount
        listPrice
        ...discountsFields
        ...sellerFields
      }
      first {
        refund
        charges {
          amount
          date
          discountAmount
          listPrice
          period
          prorated
          ...discountsFields
          ...sellerFields
        }
      }
      future {
        charges {
          amount
          date
          discountAmount
          listPrice
          prorated
          ...discountsFields
          ...sellerFields
        }
      }
    }
  }
  ${DiscountsFieldsFragmentDoc}
  ${SellerFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type EnrollmentPriceBreakdownArgs = MakeOptional<UseQueryArgs<EnrollmentPriceBreakdownQueryVariables, EnrollmentPriceBreakdownQuery>, 'query'>;

export function useEnrollmentPriceBreakdownQuery(options: EnrollmentPriceBreakdownArgs = {}) {
  return useQuery<EnrollmentPriceBreakdownQueryVariables, EnrollmentPriceBreakdownQuery>({ query: EnrollmentPriceBreakdownDocument, ...options });
}

export type EnrollmentPriceBreakdownOptions = ExecQueryOptions<EnrollmentPriceBreakdownQueryVariables>;

export function enrollmentPriceBreakdown(options: EnrollmentPriceBreakdownOptions) {
  return executeQuery<EnrollmentPriceBreakdownQuery, EnrollmentPriceBreakdownQueryVariables>(EnrollmentPriceBreakdownDocument, options);
}

export type EnrollmentPriceBreakdownSelections = EnrollmentPriceBreakdownQuery['enrollmentPriceBreakdown'];
