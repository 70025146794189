import * as React from 'react';

import { EnrollmentStatusFilter, authorizedCoursePreferenceKeys, RosterGroupingKind } from 'app2/api';
import { DataTable, MenuItem, useRef } from 'app2/components';
import { byActivity, CopyParentEmailsAction, ContentType, downloadRoster, PAGE_WIDTH, ReportView, ViewAttribute } from 'app2/views/shared';

import { CourseSelections } from '../../generated';

import { EnrollmentsTable } from '../EnrollmentsTable';
import { renderMoveButton, renderPromoteButton, renderRemoveStudentsButton, renderResendStudentsButton } from '../actions';
import { useCourseEnrollmentCols } from '../useCourseEnrollmentCols';

import { WaitlistedEnrollmentsSelections, useWaitlistedEnrollmentsQuery } from './generated';

const TABLE_PREFS_VERSION = '5';

interface Props {
  course: CourseSelections;
}

export function Waitlisted(props: Props) {
  const cols = useCourseEnrollmentCols<WaitlistedEnrollmentsSelections>(colNames, props.course, EnrollmentStatusFilter.Waitlisted);
  const tableRef = useRef<DataTable<WaitlistedEnrollmentsSelections>>();

  function render() {
    return (
      <EnrollmentsTable<WaitlistedEnrollmentsSelections>
        course={props.course}
        header={{ icon: 'Clock', title: 'Waitlisted', options: renderOptions(), secondaryActions: renderSecondaryActions() }}
        table={{ cols, ref: tableRef, none: 'No waitlisted students', pageWidth: PAGE_WIDTH }}
        view={{table:'course-waitlisted'}}
        prefsVersion={TABLE_PREFS_VERSION}
        prefsKey={authorizedCoursePreferenceKeys.waitlistedEnrollmentsTable}
        queryHook={useWaitlistedEnrollmentsQuery}
      />
    );
  }

  function renderOptions() {
    return [
      <CopyParentEmailsAction table={tableRef} />,
      <MenuItem label="Download roster PDF" onClick={handleDownloadRosterPDF} />,
      <MenuItem label="Download PDF" onClick={handleDownloadPDF} />,
      <MenuItem label="Download CSV" onClick={handleDownloadCSV} />
    ];
  }

  function renderSecondaryActions() {
    return [
      renderPromoteButton(props.course, tableRef.current),
      renderMoveButton(props.course, tableRef.current),
      renderRemoveStudentsButton(props.course, tableRef.current, true),
      renderResendStudentsButton(props.course, tableRef.current)
    ];
  }

  function handleDownloadRosterPDF() {
    return downloadRosterHelper(ContentType.PDF, byActivity, ['title', 'groups', 'cols']);
  }

  function handleDownloadPDF() {
    // wysiwyget
    return downloadRosterHelper(ContentType.PDF);
  }

  function handleDownloadCSV() {
    // wysiwyget
    return downloadRosterHelper(ContentType.CSV);
  }

  function downloadRosterHelper(type:ContentType, view?:ReportView, viewAttributes?:ViewAttribute[]) {
    const variables = {groupingId: props.course?.id, groupingKind: RosterGroupingKind.Course, enrollmentStatus: EnrollmentStatusFilter.Removed};

    return downloadRoster(['Homeroom waitlisted roster', props.course.name], type, 'rosterByGrouping', variables, tableRef.current, view, viewAttributes);
  }

  return render();
}

const colNames = [
  'student.firstName',
  'student.lastName',
  'student.grade',
  'rosterPeriods',
  'student.classroom.displayName',
  'dismissal',
  'parent.name',
  'parent.phone',
  'parent.email',
  'groups'
];