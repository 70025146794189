import * as Types from '../../../../../api/graphql';

import gql from 'graphql-tag';
export type CourseCardFieldsFragment = {
  id: string;
  name?: string | null | undefined;
  status: Types.CourseStatus;
  kind?: Types.CourseKind | null | undefined;
  grades?: Array<string | null | undefined> | null | undefined;
  ageMin?: number | null | undefined;
  ageMax?: number | null | undefined;
  noEnrichmentDays: Array<string | null | undefined>;
  maxCapacity?: number | null | undefined;
  startDate?: string | null | undefined;
  endDate?: string | null | undefined;
  classesCount: number;
  courseImage?: string | null | undefined;
  courseCardImage?: string | null | undefined;
  closed: boolean;
  waitlistOpen: boolean;
  hasCheckoutOptions: boolean;
  searchable: boolean;
  courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
  company?: { id: string; name?: string | null | undefined } | null | undefined;
  courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
  prices: {
    season: { days?: number | null | undefined; price?: number | null | undefined };
    seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
    recurring: Array<{ days?: number | null | undefined; billingUnit?: Types.RecurringBillingUnit | null | undefined; billingFrequency?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
    dropIn: { price?: number | null | undefined };
    usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
  };
  teacher?: { name: string } | null | undefined;
  site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
};

export const CourseCardFieldsFragmentDoc = gql`
  fragment courseCardFields on Course {
    id
    name
    status
    kind
    courseTags {
      id
      name
    }
    company {
      id
      name
    }
    grades
    ageMin
    ageMax
    courseDays {
      days
      start
      finish
    }
    noEnrichmentDays
    maxCapacity
    startDate
    endDate
    classesCount
    prices {
      season {
        days
        price
      }
      seasons {
        days
        price
      }
      recurring {
        days
        billingUnit
        billingFrequency
        price
      }
      dropIn {
        price
      }
      usage {
        unit
        price
      }
    }
    courseImage
    courseCardImage
    closed
    waitlistOpen
    teacher {
      name
    }
    site {
      id
      branding {
        logo
      }
      siteCompanies {
        id
      }
    }
    hasCheckoutOptions
    searchable
  }
`;
