import { RecurringRate, RecurringUnit, RecurringBillingUnit } from 'app2/api';

export function parseRecurringRates(rate: string | RecurringRate[]) {
  if (Array.isArray(rate)) {
    return rate;
  }

  if (typeof rate !== 'string') {
    return [];
  }

  // Matches formats like:
  // "$100 per week for 2 days/week, billed weekly"
  // "$150 per month for 3 days/week, billed monthly"
  // "$100 per day for 2 days (Mon, Tue), billed monthly"
  const newRe = /\$?([0-9]+) per (day|week|month) for ([1-7]) days(?:\/week)?(?:.*?), billed (weekly|monthly)/gmi;
  
  // Matches previous format:
  // e.g., "2 days/week for $100 per week"
  const oldRe = /([1-7]) days?\/week for \$?([0-9]+) per (week|month)[\s\S]?/gmi;

  // Try to match using the new pattern first,
  // if no matches, fall back to the old pattern
  let matches = [...(rate as any).matchAll(newRe)];
  let rates;

  if (matches.length > 0) {
    rates = matches.map(r => {
      return {
        days: parseInt(r[3]),
        rate: parseInt(r[1]),
        billingUnit: getBillingUnit(r[2].toLowerCase()),
        billingFrequency: r[4].toLowerCase() === 'monthly' ? RecurringUnit.Month : RecurringUnit.Week
      };
    });
  } else {
    matches = [...(rate as any).matchAll(oldRe)];
    const billingFrequency = matches[0][3].toLowerCase() === 'month' ? RecurringUnit.Month : RecurringUnit.Week;
    rates = matches.map(r => {
      return {
        days: parseInt(r[1]),
        rate: parseInt(r[2]),
        billingUnit: billingFrequency, // billing unit and frequency are the same in the old format
        billingFrequency: billingFrequency
      };
    });
  }

  return rates;
}

function getBillingUnit(unit: string): RecurringBillingUnit {
  switch(unit) {
    case 'day': return RecurringBillingUnit.Day;
    case 'week': return RecurringBillingUnit.Week;
    case 'month': return RecurringBillingUnit.Month;
    default: return RecurringBillingUnit.Month; // fallback to month if unknown
  }
}
