import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { CourseCardFieldsFragmentDoc } from '../../../shared-public/course/course-card/generated/coursesCardFields.generated';
import { RegistrationDisplayFieldsFragmentDoc } from '../../../shared/season/generated/registrationDisplay.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PublicCourseQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type PublicCourseQuery = {
  course?:
    | {
        description?: string | null | undefined;
        supplies?: Array<string | null | undefined> | null | undefined;
        enrollmentOpens?: string | null | undefined;
        enrollmentCloses?: string | null | undefined;
        id: string;
        name?: string | null | undefined;
        status: Types.CourseStatus;
        kind?: Types.CourseKind | null | undefined;
        grades?: Array<string | null | undefined> | null | undefined;
        ageMin?: number | null | undefined;
        ageMax?: number | null | undefined;
        noEnrichmentDays: Array<string | null | undefined>;
        maxCapacity?: number | null | undefined;
        startDate?: string | null | undefined;
        endDate?: string | null | undefined;
        classesCount: number;
        courseImage?: string | null | undefined;
        courseCardImage?: string | null | undefined;
        closed: boolean;
        waitlistOpen: boolean;
        hasCheckoutOptions: boolean;
        searchable: boolean;
        children: Array<{
          id: string;
          name?: string | null | undefined;
          status: Types.CourseStatus;
          kind?: Types.CourseKind | null | undefined;
          grades?: Array<string | null | undefined> | null | undefined;
          ageMin?: number | null | undefined;
          ageMax?: number | null | undefined;
          noEnrichmentDays: Array<string | null | undefined>;
          maxCapacity?: number | null | undefined;
          startDate?: string | null | undefined;
          endDate?: string | null | undefined;
          classesCount: number;
          courseImage?: string | null | undefined;
          courseCardImage?: string | null | undefined;
          closed: boolean;
          waitlistOpen: boolean;
          hasCheckoutOptions: boolean;
          searchable: boolean;
          courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
          company?: { id: string; name?: string | null | undefined } | null | undefined;
          courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
          prices: {
            season: { days?: number | null | undefined; price?: number | null | undefined };
            seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
            recurring: Array<{
              days?: number | null | undefined;
              billingUnit?: Types.RecurringBillingUnit | null | undefined;
              billingFrequency?: Types.RecurringUnit | null | undefined;
              price?: number | null | undefined;
            }>;
            dropIn: { price?: number | null | undefined };
            usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
          };
          teacher?: { name: string } | null | undefined;
          site: { id: string; branding?: { logo?: string | null | undefined } | null | undefined; siteCompanies: Array<{ id: string }> };
        }>;
        site: {
          id: string;
          name?: string | null | undefined;
          partner: boolean;
          grades?: Array<{ value: string; label: string }> | null | undefined;
          branding?: { logo?: string | null | undefined } | null | undefined;
          siteCompanies: Array<{ id: string }>;
        };
        company?:
          | {
              id: string;
              name?: string | null | undefined;
              headline?: string | null | undefined;
              about?: string | null | undefined;
              profileBackground?: string | null | undefined;
              profilePhoto?: string | null | undefined;
              cancellationPolicy?: { text: string } | null | undefined;
            }
          | null
          | undefined;
        season: {
          registrationDisplayConfig?:
            | {
                tab?: string | null | undefined;
                groups?: Array<string> | null | undefined;
                sorts?: Array<string> | null | undefined;
                layout?: Types.SeasonRegistrationLayout | null | undefined;
                showDates?: boolean | null | undefined;
              }
            | null
            | undefined;
        };
        courseTags: Array<{ id: string; name?: string | null | undefined } | null | undefined>;
        courseDays: Array<{ days?: Array<string> | null | undefined; start?: string | null | undefined; finish?: string | null | undefined } | null | undefined>;
        prices: {
          season: { days?: number | null | undefined; price?: number | null | undefined };
          seasons: Array<{ days?: number | null | undefined; price?: number | null | undefined }>;
          recurring: Array<{ days?: number | null | undefined; billingUnit?: Types.RecurringBillingUnit | null | undefined; billingFrequency?: Types.RecurringUnit | null | undefined; price?: number | null | undefined }>;
          dropIn: { price?: number | null | undefined };
          usage: Array<{ unit?: Types.UsageUnit | null | undefined; price?: number | null | undefined }>;
        };
        teacher?: { name: string } | null | undefined;
      }
    | null
    | undefined;
};

export const PublicCourseDocument = gql`
  query PublicCourse($id: ID!) {
    course(course: $id) {
      ...courseCardFields
      children {
        ...courseCardFields
      }
      description
      supplies
      enrollmentOpens
      enrollmentCloses
      site {
        id
        name
        partner
        grades {
          value
          label
        }
      }
      company {
        id
        name
        headline
        about
        profileBackground
        profilePhoto
        cancellationPolicy {
          text
        }
      }
      season {
        ...registrationDisplayFields
      }
    }
  }
  ${CourseCardFieldsFragmentDoc}
  ${RegistrationDisplayFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type PublicCourseArgs = MakeOptional<UseQueryArgs<PublicCourseQueryVariables, PublicCourseQuery>, 'query'>;

export function usePublicCourseQuery(options: PublicCourseArgs = {}) {
  return useQuery<PublicCourseQueryVariables, PublicCourseQuery>({ query: PublicCourseDocument, ...options });
}

export type PublicCourseOptions = ExecQueryOptions<PublicCourseQueryVariables>;

export function publicCourse(options: PublicCourseOptions) {
  return executeQuery<PublicCourseQuery, PublicCourseQueryVariables>(PublicCourseDocument, options);
}

export type PublicCourseSelections = PublicCourseQuery['course'];
