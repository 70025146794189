import pluralize from 'pluralize';

import { RecurringRate, RecurringUnit, RecurringBillingUnit } from 'app2/api';

export function formatRecurringRates(items:RecurringRate[]) {
  return items?.map(formatRecurringRate).join('\n');
}

export function formatRecurringRate(item: RecurringRate) {
  const unitLabels = {
    [RecurringBillingUnit.Day]: 'day',
    [RecurringBillingUnit.Week]: 'week',
    [RecurringBillingUnit.Month]: 'month'
  }

  const frequencyLabels = {
    [RecurringUnit.Week]: 'weekly',
    [RecurringUnit.Month]: 'monthly'
  }

  const rate = Number.isFinite(item.rate) ? item.rate : '?';
  const days = item.days || '?';
  const billingUnit = unitLabels[item.billingUnit] || '?';
  const billingFrequency = frequencyLabels[item.billingFrequency] || '?';

  return `$${rate} per ${billingUnit} for ${days} ${pluralize('day', item.days)}/week, billed ${billingFrequency}`;
}
