import * as React from 'react'
import pluralize from 'pluralize';
import { groupBy } from 'lodash-es';
import { commaListsAnd } from 'common-tags';
import moment from 'moment';

import { CartUtils, CourseOptionKind, CourseUtils, DeepPartial, EnrollmentUtils, PriceConfigKind, RecurringPriceConfig, SeasonPriceConfig, formatAge } from 'app2/api';
import { DatePicker, DropdownField, Field, FieldInfo, FieldRendererProps, FormModel, formatDate, Info, Modal, Panel, Part, RepeatingSection, HBox, VBox, Text, formatCurrency, useForm, useShield } from 'app2/components';
import { CourseGrades, coursePriceUnitLabels, coursePriceBillingAdverbs, PublicCompanyLink, PublicCourseLink, removeCourseFromCart, CourseKindBehavior, courseKindBehavior } from 'app2/views/shared-public';
import { CourseDatesDisplay, CourseDaysDisplay, DiscountTag, errorPathTransformHandler, WaitlistBadge } from 'app2/views/shared';

import { AfterClass, updateCartedEnrollment, BeforeClass, useClassOptions, UseClassOptionsSite, EnrollmentWithClassOption } from 'app2/views/parent/shared';

import { AddStudentModal } from './AddStudentModal';
import { CheckoutModel, CartItem, Student } from './CheckoutModel';
import { Discount } from './Discount';
import { parentRemoveDiscount } from './generated';
import { WaitlistWarning } from './WaitlistWarning';
import { PriceTier } from './PriceTier';

type Enrollment = CheckoutModel['cart']['enrollments'][0]; 

interface Props {
  form:FormModel<CheckoutModel>;
}

export function Cart(props:Props) {
  const user = props.form.values.user;
  const cart = user?.cart;
  const students = getStudentOptions();
  const { allHaveBeforeClass, hasBeforeClass, siteOptions } = useClassOptions((cart?.sites as unknown) as UseClassOptionsSite[]);
  const usingTiers = cart?.priceTierUse || cart?.sites?.some(s => s.priceTiers.length);

  const {accepted, visible:enrollments, outOfGradeRange, outofAgeRange} = React.useMemo(() => CartUtils.classifyEnrollments(cart), [cart.enrollments]);
  const form = useForm({enrollments}, [enrollments])

  const shield = useShield();

  function render() {
    return (
      <>
        <VBox>
          {usingTiers && <PriceTier />}
          <Discount />
          <Panel icon='Users' title='Your items' width='100%' form={form} type='edit-no-save-button' onNavigation='nothing' autoFocus={!usingTiers} showUnhandledErrors={false} primaryActions={renderCartTotal()} breakpoints={[500, 1024]} containerBreakpoints={false}>
            <RepeatingSection name='enrollments' separator onRemove={onRemoveFromCart} none="Your cart is empty" equalWidths={false} fields={[
              <Field render={renderCourse} />,
              <Field render={renderStudent} />,
              hasBeforeClass && <Field render={({info}:{info:FieldInfo<Enrollment>}) => <BeforeClass allHaveBeforeClass={allHaveBeforeClass} siteOptions={siteOptions} onChange={onChangeBeforeClass} editable={isNotOptionOrOptionWithoutParent(info)} />} />,
              <Field render={({info}:{info:FieldInfo<Enrollment>}) => <AfterClass siteOptions={siteOptions} onChange={onChangeAfterClass} editable={isNotOptionOrOptionWithoutParent(info)} />} />,
              <Field render={renderAmount} />,
              <Field name='remove' render={isRemoveable} width='14px' />
            ]} />
          </Panel>
        </VBox>
        <VBox gap='$8'>
          {renderOutOfRangeWarnings()}
          <WaitlistWarning />
        </VBox>
      </>
    )
  }

  function isRemoveable({info}:{info:FieldInfo<Enrollment>}) {
    return isNotOptionOrOptionWithoutParent(info) || info.value.courseOption.kind == CourseOptionKind.MultipleChoice;
  }

  function isNotOptionOrOptionWithoutParent(info:FieldInfo<Enrollment>) {
    return !info.value.isOption || !cartHasEnrollment(info.value.optionParent?.id)
  }

  function renderCartTotal() {
    return <Text text='body' fontWeight="bold">{formatCurrency(cart?.total)} total</Text>
  }

  function renderCourse(props:FieldRendererProps<CartItem[], 0>) {
    const behavior = getSeasonBehavior(props.info.value);
    
    return <VBox minWidth='140px'>
      <HBox vAlign='baseline' mb='$8'>
        <Field name='course.name' component={PublicCourseLink} underline={false} text='subtitle2' mr='$10' option={props.info.value.isOption} />
        <Field name='status' cart component={WaitlistBadge} />
      </HBox>
        <>
          {behavior.showCompanyName && (
            <VBox width='100%' gap='$8' mb='$30'>
              <HBox width='100%' vAlign='center' hAlign='justify' gap='$8'>
                <Text text='body'>by <Field name='course.company.name' component={PublicCompanyLink} /></Text>
                <CourseGrades mb='0' mr='$8' course={props.info.value.course} />
              </HBox>
            </VBox>
          )}
          {renderMeets(props)}
        </>
    </VBox>
  }

  function renderStudent(props:FieldRendererProps<CartItem[], 0>) {
    const e = props.info.value;

    if (!e.studentSelectionRequired) {
      return null;
    }

    const warningProps = e.inGradeRange === false
      ? {infoTip:`Please review your selection. ${e.student.name} is outside the grade range (${e.course.gradeGroup}) for ${e.course.name}${conditionalMsg(`, as they will be in grade ${e.gradeAtStart} when the activity begins`, e.gradeAtStart != getStudent(e.student.id)?.grade)}.`, infoTipIcon:{name: 'AlertTriangle' as any, strokeWidth: 2, color: 'warningText'}}
      : e.inAgeRange === false 
       ? {infoTip:`Please review your selection. ${e.student.name} is outside the age range (${e.course.ageGroup}) for ${e.course.name}${conditionalMsg(`, as they will be ${formatAge(e.ageAtStart)} when the activity begins`, e.ageAtStart != getStudent(e.student.id)?.age)}.`, infoTipIcon:{name: 'AlertTriangle' as any, strokeWidth: 2, color: 'warningText'}}
       : undefined;

    return <Part<any> {...warningProps} name='student.id' label='Assign student' required={studentRequired} editing={!e.isOption} onChange={onChangeStudent} component={{...DropdownField, options: students, edit:{...DropdownField.edit, placeholder:'Select student', options: students, additions:'student', addPosition:'top', onAdd: (name:string) => onAddStudent(name, e), autoLoader: true, disablePhoneInput: true }}} />
  }

  function renderAmount(props: FieldRendererProps<CartItem[], 0>) {
    const enrollment = props.info.value;
    const amountComponent = renderPriceDetails(enrollment);
    const seasonBehavior = getSeasonBehavior(props.info.value);
    const message = renderPriceMessage(seasonBehavior, enrollment);

    return (
      <VBox hAlign="right" gap="$4">
        {amountComponent}
        {message && (
          <Text text="body" textAlign="right" maxWidth={['unset', '200px']}>
            {message}
          </Text>
        )}
        {renderDiscounts(enrollment)}
      </VBox>
    );
  }

  function renderDiscounts(enrollment: Enrollment) {
    const withDiscounts = CartUtils.enrollmentDiscounts(enrollment, cart)

    return <VBox gap="$4">
      {withDiscounts?.discountUses?.map((du, i) => (
        <DiscountTag key={i} code={du.code} formattedAmount={du.formattedAmount} icon={du.cartRemovable ? 'X' : null} autoLoader onCloseClick={() => parentRemoveDiscount({ variables: { id: du.discountDefinitionId } })} />
      ))}
    </VBox>
  }

  function renderPriceMessage(seasonBehavior: CourseKindBehavior, enrollment: Enrollment) {
    switch (enrollment.priceConfig.kind) {
      case PriceConfigKind.Season:
        const prices = CartUtils.enrollmentPrices(enrollment, cart);
        const deposit = prices.deposit !== undefined && !EnrollmentUtils.waitlistUnfinalized(enrollment) ? <Text>{formatCurrency(prices.deposit)} today</Text> : undefined;
        const classes = seasonBehavior.showSessionCount && <Field name='course.classesCount' text='body' whiteSpace='nowrap' format={(val:number) => `${val} ${pluralize(seasonBehavior.terms.session, val)}`} readOnly />;

        return deposit || classes ? <VBox>{deposit}{classes}</VBox> : null;

      case PriceConfigKind.Recurring:
        // Expected behavior for recurring:
        // - https://joinhomeroom.slack.com/archives/C03VDL4147K/p1687356981727359
        // - https://github.com/homeroom/frontend/pull/3149#issue-1766383154
        const prorated = enrollment.firstPayment?.prorated;
        const dueOn = moment(enrollment.firstPayment?.dueOn).utc();
        const isDueToday = !EnrollmentUtils.waitlistUnfinalized(enrollment) && dueOn.isSameOrBefore(moment.utc(), 'day');
        return isDueToday ? (prorated ? `${formatCurrency(enrollment.firstPayment?.amount)} today` : null) : `${formatCurrency(0)} today`

      case PriceConfigKind.Usage:
        return `billed ${coursePriceBillingAdverbs[EnrollmentUtils.billingUnit(enrollment)]}`;

        default:
        return null;
    }
  }

  function renderPriceDetails(enrollment: Enrollment) {
    const waitlisted = EnrollmentUtils.waitlistUnfinalized(enrollment);

    if (waitlisted) {
      return <Text text="subtitle2" whiteSpace="nowrap">{formatCurrency(0)}</Text>;
    }
    
    const withDiscounts = CartUtils.enrollmentDiscounts(enrollment, cart)
    const hasDiscount = withDiscounts.discountUses.length > 0;
    const {price, listPrice} = CartUtils.enrollmentPrices(enrollment, cart);
    const unitLabel = (enrollment.priceConfig.kind === PriceConfigKind.Recurring || enrollment.priceConfig.kind === PriceConfigKind.Usage) 
      ? `/${coursePriceUnitLabels[EnrollmentUtils.billingUnit(enrollment)]}` 
      : '';
  
    return (
      <HBox gap="$4">
        {hasDiscount && (
          <Text text="subtitle2" whiteSpace="nowrap" textDecoration="line-through">
            {formatCurrency(listPrice) + unitLabel}
          </Text>
        )}
        <Text text="subtitle2" whiteSpace="nowrap">
          {formatCurrency(price) + unitLabel}
        </Text>
      </HBox>
    );
  }

  function renderMeets(props: FieldRendererProps<CartItem[], 0>) {
    const behavior = getSeasonBehavior(props.info.value)
    
    if (behavior.sessionType == 'none') {
      return null;
    }

    const meets = behavior.sessionType == 'days' ? renderDaysMeets(props) : renderDatesMeets(props);

    return <HBox>{meets}</HBox>;
  }

  function renderDaysMeets(props:FieldRendererProps<CartItem[], 0>) {
    const enrollment = props.info.value;
    const priceRenderers = {
      [PriceConfigKind.ConfigurableSeason]: renderMeetsSeason,
      [PriceConfigKind.Custom]: () => <div />, // Custom charges aren't displayed at checkout
      [PriceConfigKind.Season]: renderMeetsSeason,
      [PriceConfigKind.DropIn]: renderMeetsDropIn,
      [PriceConfigKind.Recurring]: renderMeetsRecurring,
      [PriceConfigKind.Usage]: renderMeetsUsage,
    }
    const kind = EnrollmentUtils.usingConfigurableSeasonPricing(props.info.value) ? PriceConfigKind.ConfigurableSeason : enrollment.priceConfig.kind;

    return priceRenderers[kind](enrollment);
  }

  function renderMeetsSeason(e:Enrollment) {
    const days = EnrollmentUtils.usingConfigurableSeasonPricing(e) ? (e.priceConfig as SeasonPriceConfig).weekdays : null;
    
    return <Field name='course.courseDays' component={CourseDaysDisplay} days={days} />;
  }

  function renderMeetsDropIn(e:Enrollment) {
    return <span>Drop-in for <b>{formatDate(e.startDate, 'ddd')}</b>, {formatDate(e.startDate, 'long')}</span>;
  }

  function renderMeetsRecurring(e:Enrollment) {
    const days = (e.priceConfig as RecurringPriceConfig).weekdays;
    const dates = CourseUtils.getCourseDates(e.course, days);

    return <VBox gap='$4' width={['100%', 'unset']}>
      <Field name='course.courseDays' component={CourseDaysDisplay} days={days} />
      <Field name="billPeriod" />
      {!(e.priceConfig as RecurringPriceConfig).startDateDisabled && <Part<any> label='Starts' name='priceConfig.startDate' component={DatePicker} width={['100%', '150px']} legend={dates.legend} min={e.course.startDate} max={e.course.endDate} onChange={onChangeStartDate} />}
    </VBox>;
  }

  function renderMeetsUsage(e:Enrollment) {
    return 'Flexible attendance, pay for what you use'
  }

  function renderDatesMeets(props:FieldRendererProps<CartItem[], 0>) {
    const enrollment = props.info.value;
    const priceRenderers = {
      [PriceConfigKind.ConfigurableSeason]: renderDatesMeetsSimple,
      [PriceConfigKind.Custom]: () => <div/>, // Custom charges aren't displayed at checkout
      [PriceConfigKind.Season]: renderDatesMeetsSimple,
      [PriceConfigKind.DropIn]: renderMeetsDropIn,
      [PriceConfigKind.Recurring]: renderMeetsRecurring,
      [PriceConfigKind.Usage]: renderDatesMeetsSimple,
    }
    const kind = EnrollmentUtils.usingConfigurableSeasonPricing(props.info.value) ? PriceConfigKind.ConfigurableSeason : enrollment.priceConfig.kind;

    return priceRenderers[kind](enrollment);
  }

  function renderDatesMeetsSimple(e:Enrollment) {
    const days = EnrollmentUtils.usingConfigurableSeasonPricing(e) ? (e.priceConfig as SeasonPriceConfig).weekdays : null;
    
    return <VBox gap='$4'>
      <Field name='course' component={CourseDatesDisplay} />
      <Field name='course.courseDays' component={CourseDaysDisplay} days={days} />
    </VBox>
  }

  function renderOutOfRangeWarnings() {
    if (!outOfGradeRange.length && !outofAgeRange.length) {
      return;
    }

    const gradeByStudent = groupBy(outOfGradeRange, e => e.student.name);
    const gradeStudents = Object.keys(gradeByStudent);
    const gradeWarnings = gradeStudents.length ? 'Please review your selections —  ' + gradeStudents.map(name => `${name} is outside the grade range ${commaListsAnd`${gradeByStudent[name].map(renderEnrollmentOutsideGradeRange)}`}`).join('; ') : null;

    const ageByStudent = groupBy(outofAgeRange, e => e.student.name);
    const ageStudents = Object.keys(ageByStudent);
    const ageWarnings = ageStudents.length ? 'Please review your selections —  ' + ageStudents.map(name => `${name} is outside the age range ${commaListsAnd`${ageByStudent[name].map(e => `${e.course.ageGroup} for ${e.course.name}${conditionalMsg(` (they will be age ${formatAge(e.ageAtStart)} at activity start`, e.ageAtStart != getStudent(e.student.id)?.age)})`)}`}`).join('; ') : null;

    return <Info type='warning'>{gradeWarnings}{ageWarnings}</Info>
  }

  function renderEnrollmentOutsideGradeRange(e:DeepPartial<Enrollment>) {
    return `${e.course.gradeGroup} for ${e.course.name} ${conditionalMsg(`(they will be in grade ${e.gradeAtStart} at activity start)`, e.gradeAtStart != getStudent(e.student.id)?.grade)}`;
  }

  function conditionalMsg(msg:string, condition:boolean) {
    return condition ? msg : '';
  }

  function onAddStudent(name:string, enrollment:CartItem) {
    Modal.show<Student>(<AddStudentModal family={user.cart.family.id} name={name} enrollment={enrollment} site={enrollment.course.site.id} courseKinds={CartUtils.getCourseKinds(cart, enrollments)} />);
  }

  async function onChangeStudent(studentId: string, info: FieldInfo<CartItem>) {
    const enrollment = info.values[2];
    const student = getStudent(studentId);

    return await updateCartedEnrollment(enrollment.id, enrollment.course, {student}, {shield});
  }

  function getStudent(id:string) {
    return user.cart.family.students.find(s => s.id == id);
  }

  async function onChangeBeforeClass(pickup: string, info: FieldInfo<EnrollmentWithClassOption>) {
    const enrollment = info.values[2];
    return updateCartedEnrollment(enrollment.id, enrollment.course, { beforeClassPickup: pickup });
  }

  async function onChangeAfterClass(dismissal: string, info: FieldInfo<EnrollmentWithClassOption>) {
    const enrollment = info.values[2];
    return updateCartedEnrollment(enrollment.id, enrollment.course, {afterClassDismissal: dismissal });
  }

  async function onChangeStartDate(startDate:string, info: FieldInfo<EnrollmentWithClassOption>) {
    const enrollment = info.values[2];
    return updateCartedEnrollment(enrollment.id, enrollment.course, { startDate }, {error: errorPathTransformHandler(form, 'startDate', 'enrollments.id-' + enrollment.id + '.priceConfig.startDate')});
  }

  async function onRemoveFromCart(cartItemPos:number) {
    const id = enrollments[cartItemPos].id;
    return removeCourseFromCart({variables:{id}});
  }

  function getStudentOptions() {
    return React.useMemo(() => {
      return user?.cart?.family?.students?.map?.(s => ({value: s.id, label: s.name}), [user?.cart?.family?.students]);
    }, [user]);
  }

  function getSeasonBehavior(enrollment:CartItem) {
    return courseKindBehavior[enrollment.course.kind];
  }

  function cartHasEnrollment(id:string) {
    return id && accepted.find(e => e.id == id);
  }
  
  return render();
}

function studentRequired(info:FieldInfo<string>) {
  const enrollment = info.values[2] as unknown as CartItem;

  return enrollment.studentSelectionRequired;
}
